import { memo } from "react";
import { Box } from "@mantine/core";

export type Props = {
  embedUrl: string;
  width: string;
  height: string;
  title: string;
};

const SlideEmbedInner = ({ embedUrl, width, height, title }: Props) => {
  return (
    <Box sx={{}}>
      {embedUrl && (
        <iframe
          title={title}
          src={embedUrl}
          width={width}
          height={height}
          // style="border:1px solid #CCC; border-width:1px; margin-bottom:5px;max-width: 100%;"
          allowFullScreen
        ></iframe>
      )}
    </Box>
  );
};

export const SlideEmbed = memo(SlideEmbedInner);
