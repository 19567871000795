import React, { useState } from "react";

import { Box } from "@mantine/core";
import { UrlBox } from "./UrlBox";
import { SlideBox } from "./SlideBox";

export const Layout = () => {
  return (
    <Box sx={{ marginTop: "50px" }}>
      <Box
        sx={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "30px",
        }}
      >
        <UrlBox />
      </Box>
      <Box
        sx={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
      >
        <Box
          sx={{
            display: "inline-block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <SlideBox />
        </Box>
      </Box>
    </Box>
  );
};
