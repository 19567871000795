import { BrowserRouter as Router } from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { Layout } from "./component/Layout";

export default function App() {
  return (
    <Router>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Layout />
      </MantineProvider>
    </Router>
  );
}
