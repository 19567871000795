import { Box, TextInput } from "@mantine/core";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

export const UrlBox = () => {
  const navigate = useNavigate();
  const params = useSearchParams();
  const [url, setUrl] = useState<string>("");
  useEffect(() => {
    setUrl(params[0].get("url") || "");
  }, []);

  return (
    <Box sx={{}}>
      <TextInput
        placeholder="Enter slide URL"
        value={url}
        onChange={(ev) => setUrl(ev.currentTarget.value)}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            const params = new URLSearchParams({ url: ev.currentTarget.value });
            navigate(`/?${params.toString()}`);
          }
        }}
        variant="filled"
        onClick={(ev) => ev.currentTarget.select()}
      />
    </Box>
  );
};
