const embedGeneratorUrl = process.env.REACT_APP_EMBED_GENERATOR_URL as string;

type ApiResponse = {
  type?: string;
  embedUrl: string;
  originalUrl: string;
  width: string;
  height: string;
  title: string;
};

export async function getEmbedInfo(pageUrl: string): Promise<ApiResponse> {
  // fetch original slideshare page
  try {
    const fetchUrl = new URL(embedGeneratorUrl);
    const params = new URLSearchParams({ url: pageUrl });
    fetchUrl.search = params.toString();

    const res = await fetch(fetchUrl.toString());
    const data = (await res.json()) as ApiResponse;
    return data;
  } catch (err) {
    console.error(err);
    return { embedUrl: "", originalUrl: "", width: "", height: "", title: "" };
  }
}
