import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Title } from "@mantine/core";
import { getEmbedInfo } from "../util";
import { SlideEmbed } from "./SlideEmbed";

const scale = 0.8;
const hOffset = 150;
const hScale = 0.9;

export const SlideBox = () => {
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [originalUrl, setOriginalUrl] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const params = useSearchParams();

  useEffect(() => {
    (async () => {
      const slideUrl = params[0].get("url") || "";

      const {
        embedUrl: newEmbedUrl,
        originalUrl: newOriginalUrl,
        title: newTitle,
      } = await getEmbedInfo(slideUrl);

      setTitle(newTitle);
      setEmbedUrl(newEmbedUrl);
      setOriginalUrl(newOriginalUrl);
    })();
  }, [params]);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Box sx={{}}>
      <Box sx={{ borderBottom: "20px", textDecoration: "none" }}>
        <Title order={1}>
          <a
            href={originalUrl}
            style={{ color: "black", textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        </Title>
      </Box>
      <SlideEmbed
        title={title}
        embedUrl={embedUrl}
        width={(windowWidth * scale).toFixed()}
        height={((windowHeight - hOffset) * hScale).toFixed()}
      />
    </Box>
  );
};
